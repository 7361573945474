import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { FaSync } from "react-icons/fa";
import { FaMoneyCheckAlt } from 'react-icons/fa';

import SubHeader from "../Layout/SubHeader";
import OrderTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";
import SearchOrderModal from "./SearchOrderModal";
import CreditNoteItemsModal from "./CreditNoteItemsModal";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import { filterGroupToQuery, filterDateToQuery } from "../../helpers/Query";
import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { getDefaultOrderFiltersSchema, creditNoteDateLimitedFilterSchema } from "../../helpers/Filters";

const Returns = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [procesingDownload, setProcesingDownload] = useState(false);

    const [creditNoteOrder, setCreditNoteOrder] = useState(null);
    const [searchOrder, setSearchOrder] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const [orderFilters, setOrderFilters] = useState({
        ...getDefaultOrderFiltersSchema(salesShopGroup),
        ...creditNoteDateLimitedFilterSchema,
        dateRangeOptions: undefined,
        statuses: [
            { id: "returning", name: "returning", value: true },
            { id: "returned", name: "returned", value: false },
        ],
    });

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const customActionPerRow = (rowOrder) => {
        return !rowOrder.creditNoteCreatedAt ?
        (
        <OverlayTrigger
            key="top"
            placement="top"
            overlay={
                <Tooltip id="tooltipNc" className="text-center">
                    Crear nota de crédito
                </Tooltip>
            }
        >
            <Button size="md" variant="light" onClick={() => setCreditNoteOrder(rowOrder)} alt="Crear nota de crédito">
                <FaMoneyCheckAlt style={{ fontSize: "1.2rem" }} />
            </Button>
        </OverlayTrigger>
        ) : null;
    };

    const customOrderFields = [
        { name: "Tienda", id: "shop" },
        { name: "Número", id: "idShop" },
        { name: "Cliente", id: "clientName" },
        { name: "Fecha creación", id: "createdAt" },
        { name: "Fecha nota crédito", id: "creditNoteCreatedAt" },
        { name: "Tipo envío", id: "shippingType" },
        { name: "Estado", id: "status" },
        { name: "Marca", id: "brand" },
        { name: "Ítems", id: "items" },
        { name: "Total", id: "totalAmount" },
        { name: "Boleta enviada", id: "invoiceSentAt" },
    ];
    
    const handleOrderSelect = useCallback(
        (orderId, checked) => {
            //console.log("Cambia item ", orderId, " checked ", checked);
    
            if (checked) {
                setSelectedOrders((oldSelectedOrders) => {
                    if (oldSelectedOrders.includes(orderId)) {
                        return oldSelectedOrders;
                    } else {
                        return [...oldSelectedOrders, orderId];
                    }
                });
            } else {
                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter((id) => id !== orderId)
                );
            }
        },
        [],
    );

    const fetchOrders = useCallback(() => {
        setOrdersLoading(true);

        let queryFilters = "?group=returns&";
        queryFilters += (filterGroupToQuery("status", orderFilters.statuses));
        queryFilters += filterGroupToQuery("brand", orderFilters.brands);
        queryFilters += filterGroupToQuery(
            "shippingType",
            orderFilters.shippingTypes
        );
        queryFilters += filterDateToQuery("creditNoteDate", orderFilters.creditNoteDate);
        queryFilters += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + queryFilters, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
                setSelectedOrders(ordersResponse.data.map( order => order._id));
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    const handleDownload = useCallback(
        () => {
            setProcesingDownload(true);
    
            const reportRequest = {
                selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
            };
    
            console.log("Generating returns report for: ", reportRequest.selectedOrders);
    
            axios
                .post(env.webApiBaseUrl + "/orders/returns", reportRequest, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                        responseType: 'blob',
                    },
                    withCredentials: true,
                })
                .then((reportResponse) => {
                    const fileContent = reportResponse.data;

                    const filename = "devueltas-" + Date.now() + ".csv";
                    fileDownload(fileContent, filename, "text/csv", "\uFEFF");
    
                    setSelectedOrders([]);
                    fetchOrders();
                })
                .catch((error) => {
                    console.error(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude generar el reporte.",
                    });
                })
                .finally(() => {
                    setProcesingDownload(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, items, selectedOrders, user.token],
    );


    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        const cleanSelectedOrders = () => {
            // Revisar que todo lo que está en selectedOrders exista
        };

        cleanSelectedOrders();
    }, [orders]);

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>

                <Button
                    variant="primary"
                    className="float-right mt-1 mb-2 ml-2"
                    disabled={!selectedOrders.length || procesingDownload}
                    onClick={() => handleDownload()}
                >
                    Descargar reporte ventas devueltas
                    {procesingDownload ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
   
                <Button
                    variant="danger"
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={() => setSearchOrder(true)}
                >
                    Crear otra nota de crédito
                </Button>

                <h2>
                    VENTAS EN DEVOLUCIÓN
                    <span className="quantities">
                        <Badge className="ml-3" variant="dark">
                            {orders.length} ventas
                        </Badge>
                        <Badge className="ml-2" variant="info">
                            {itemQuantity} ítems
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData
                ? <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                : null}

                {searchOrder ?
                    <SearchOrderModal
                        handleOrderSelected={setCreditNoteOrder}
                        handleCancel={() => setSearchOrder(false)}
                    />
                : null
                }

                {creditNoteOrder ?
                <CreditNoteItemsModal 
                    order={creditNoteOrder}
                    setOrder={setCreditNoteOrder}
                    fetchOrders={fetchOrders}
                />
                : null
                }

                <br />

                <OrderTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    ordersLoading={ordersLoading}
                    customFields={customOrderFields}
                    customActionsFunction={customActionPerRow}
                />
            </Container>
        </div>
    );
};

export default Returns;
