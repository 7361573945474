export const packingExpectedDateReached = (order) => {
    if (order.extraData && 
        order.extraData.shipping && 
        order.extraData.shipping.substatus === "buffered" &&
        order.extraData.shipping.expectedPackingDate) {
        const today = new Date();
        const expected = new Date(order.extraData.shipping.expectedPackingDate);

        //console.log("today:", today, " expected:", expected);
        if (expected <= today) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
};

export const getExpectedPackingDate = (order) => {
    if (order.extraData && 
        order.extraData.shipping && 
        order.extraData.shipping.expectedPackingDate) {
        let rawPackingDate = new Date(order.extraData.shipping.expectedPackingDate.substr(0,10));
        rawPackingDate.setDate(rawPackingDate.getDate() + 1); // viene en GMT y a las 00:00

        return rawPackingDate.getDate() + "-" + (rawPackingDate.getMonth() + 1) + "-" + rawPackingDate.getFullYear();
    }

    return null;
};

export const isBuffered = (order) => {
    if (order.extraData && 
        order.extraData.shipping && 
        order.extraData.shipping.substatus === "buffered") {
        return true;
    }

    return false;
};
