import React, { useCallback, useContext, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

import SearchOrderModalHeader from "./SearchOrderModalHeader";
import SearchOrderForm from "./SearchOrderForm";
import InfoMessage from '../Layout/InfoMessage';

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import { getShopGroupFromOrder } from "../../helpers/ShopGroups";

const SearchOrderModal = ({
    handleOrderSelected,
    handleCancel,
}) => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [validated, setValidated] = useState(false);
    const [search, setSearch] = useState({
        type: "idShop",
        value: "",
    });
    
    const [infoMessageData, setInfoMessageData] = useState({});

    const idTypes = [
        { id: "trxNumber", name: "Número de transacción", placeholder: "Ej: 05610200027350" },
        { id: "orderId", name: "Código de venta", placeholder: "Ej: 620a71d52aa92c2e8059b027" },
        { id: "idShop", name: "Número de venta original", placeholder: "Ej: 2000002833876388" },
    ];

    const [orderLoading, setOrderLoading] = useState(false);

    const handleOrderLoaded = useCallback(
        (order) => {
            /*if (["picking", "packing", "review"].includes(order.status)) {
                setInfoMessageData({
                    type: "error",
                    msg:
                        "La venta seleccionada está en estado '" +
                        order.status +
                        "'. No se puede procesar una devolución.",
                    retryButton: true,
                });
            } else */
            if (getShopGroupFromOrder(order) !== salesShopGroup) {
                setInfoMessageData({
                    type: "error",
                    msg: "La venta seleccionada es de un origen distinto a " + salesShopGroup + ", no se puede agregar a la lista.",
                    retryButton: true,
                });
            } else if (order.status === "refunded") {
                setInfoMessageData({
                    type: "prompt",
                    msg: "Esta venta ya está en estado refunded.\n¿Seguro que quieres continuar?",
                    ok: () => {handleOrderSelected(order); setInfoMessageData({})},
                    cancel: () => setInfoMessageData({}),
                });
            } else {
                handleOrderSelected(order);
            }
        },
        [handleOrderSelected, salesShopGroup, setInfoMessageData]
    );

    const handleLoadOrder = useCallback(
        (value, type) => {
            setOrderLoading(true);
            let query = "/orders/" + value;
            if (type === "trxNumber") {
                query = "/orders/byTrx/" + value;
            }
            else if (type === "idShop") {
                query = "/orders/byIdShop/" + salesShopGroup + "/" + value;
            }

            axios
                .get(env.webApiBaseUrl + query, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((orderResponse) => {
                    const order = orderResponse.data;
                    if (!order || !order._id) {
                        setInfoMessageData({
                            type: "error",
                            msg: "Error al recuperar la venta.",
                            retryButton: true,
                        });
                    } else {
                        handleOrderLoaded(order);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setInfoMessageData({
                        type: "error",
                        msg: "No pude recuperar la venta. Verifica que el código ingresado sea válido.",
                        retryButton: true,
                    });
                })
                .finally(() => {
                    setOrderLoading(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, handleOrderLoaded, salesShopGroup, user.token]
    );

    const handleChange = useCallback(
        (field, value) => {
            setSearch(oldSearch => ({
                ...oldSearch,
                [field]: value,
            }));

            if (field === "id") {             
                // auto submit
                if (
                    search.type === "orderId" && value.length === 24
                    ) {
                    handleLoadOrder(search.value, search.type);
                }
            }
        },
        [handleLoadOrder, search.type, search.value]
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            setValidated(true);

            handleLoadOrder(search.value, search.type); // Agregar casos con id typo trx o idShop
        },
        [handleLoadOrder, search.type, search.value]
    );

    return (
        <Modal 
            size="md"
            backdrop="static"
            show={true}
            onHide={handleCancel}
        >
            <SearchOrderModalHeader
                handleCancel={handleCancel}
            />

            <Modal.Body style={{ height: "60vh", overflowY: "auto", overflowX: "auto" }}>
            {infoMessageData && infoMessageData.type
                ? <div className="text-center w-50 m-auto">
                    <InfoMessage
                        type={infoMessageData.type}
                        closeButton={infoMessageData.closeButton}
                        retryButton={infoMessageData.retryButton}
                        msg={infoMessageData.msg}
                        setMsg={setInfoMessageData}
                    />
                </div>
                : <SearchOrderForm
                    orderLoading={orderLoading}
                    idTypes={idTypes}
                    search={search}
                    validated={validated}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            }
            </Modal.Body>
        </Modal>
    );
};

export default SearchOrderModal;
