import React, { useState } from 'react';
import Container from "react-bootstrap/Container";

import InfoModal from "../Layout/InfoModal";
import ResyncOrder from "./ResyncOrder";
import SubHeader from "../Layout/SubHeader";

const Tools = () => {
    const [infoModalData, setInfoModalData] = useState(null);

    return (
        <div>
            <SubHeader>
                <h2>
                    HERRAMIENTAS
                </h2>
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData && infoModalData.msg
                ? (
                    <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) 
                : null
                }
                
                <ResyncOrder
                    setInfoModalData={setInfoModalData}
                />
            </Container>
        </div>
    );
}
 
export default Tools;