export const dateFilterSchema = {
    dateRangeOptions: [
        { id: "any", name: "Todo" },
        { id: "today", name: "Hoy" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    date: {
        range: "any",
        from: null,
        until: null,
    },
};

export const defaultOrderFiltersSchema = {
    statuses: [
    ],
    shippingTypes: [
        { id: "bluex_normal", name: "Normal", value: true },
        { id: "bluex_priority", name: "Priority", value: true },
        { id: "boosmap_normal", name: "24Hrs", value: true },
        { id: "cross_docking", name: "Colecta", value: true },
        { id: "self_service", name: "Flex", value: true },
        { id: "drop_off", name: "Envíos", value: true },
        { id: "xd_drop_off", name: "Agencias", value: true },
        { id: "fulfillment", name: "Full", value: true },
        { id: "other", name: "Otro", value: true },
    ],
    ...dateFilterSchema,
    brands: [
        { id: "Marca 1", name: "Marca 1", value: true },
        { id: "Marca 2", name: "Marca 2", value: true },
        { id: "Marca 3", name: "Marca 3", value: true },
        { id: "Marca 4", name: "Marca 4", value: true },
        { id: "Marca 5", name: "Marca 5", value: true },
        { id: "Marca 6", name: "Marca 6", value: true },
        { id: "Otra", name: "Otra", value: true },
        { id: "Mixto", name: "Mixto", value: true },
    ],
    shopGroups: [
        //{ id: "PZ", name: "PZ", value: true },
        { id: "MercadoLibre", name: "MercadoLibre", value: false },
        { id: "Ripley", name: "Ripley", value: false },
        { id: "paris.cl", name: "Paris", value: false },
    ],
    groups: ["statuses", "shippingTypes", "brands", "shopGroups"],
};

export const dateLimitedFilterSchema = {
    dateRangeOptions: [
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "today", name: "Hoy" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    date: {
        range: "today",
        from: null,
        until: null,
    },
};

export const creditNoteDateLimitedFilterSchema = {
    creditNoteDateRangeOptions: [
        { id: "none", name: "Sin nota de crédito" },
        { id: "last30Days", name: "Últimos 30 días" },
        { id: "last7Days", name: "Últimos 7 días" },
        { id: "last3Days", name: "Últimos 3 días" },
        { id: "today", name: "Hoy" },
        { id: "custom", name: "Día específico" },
        { id: "range", name: "Rango personalizado" },
    ],
    creditNoteDate: {
        range: "none",
        from: null,
        until: null,
    },
};

export const dateLimitedOrderFiltersSchema = {
    ...defaultOrderFiltersSchema,
    ...dateLimitedFilterSchema,
};

export const getDefaultOrderFiltersSchema = (salesShopGroup) => {
    return {
        ...defaultOrderFiltersSchema,
        shopGroups: defaultOrderFiltersSchema.shopGroups.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};

export const getDateLimitedOrderFiltersSchema = (salesShopGroup) => {
    return {
        ...dateLimitedOrderFiltersSchema,
        shopGroups: defaultOrderFiltersSchema.shopGroups.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};

export const getShopGroupsSchema = (salesShopGroup) => {
    const shopGroupsSchema = [
        { id: "PZ", name: "PZ", value: true },
        { id: "MercadoLibre", name: "MercadoLibre", value: false },
        { id: "Ripley", name: "Ripley", value: false },
        { id: "paris.cl", name: "Paris", value: false },
    ];

    return {
        shopGroups: shopGroupsSchema.map((shopGroup) => {
            return {
                id: shopGroup.id,
                name: shopGroup.name,
                value: salesShopGroup === shopGroup.id,
            };
        }),
    };
};
