import React, { Fragment } from 'react';
import Badge from 'react-bootstrap/Badge';
import NumberFormat from 'react-number-format';

import OrderItemPackingStatus from './OrderItemPackingStatus';
import StatusHistory from './StatusHistory';
import ShippingLabel from './ShippingLabel';
import Receipt from './Receipt';
import CreditNote from './CreditNote';
import Picking from './Picking';

import { getExpectedPackingDate } from "../../helpers/Packing";

const OrderField = ({ order, field }) => {
    return (
        <Fragment>
            {
                {
                    "shop":
                        <td>{order.shop}</td>,
                    "idShop":
                        <td className="text-center">
                            {order.idShop}
                            {order.status !== "pending"
                                ? <div className="pt-2">
                                    <Picking
                                        orderId={order._id}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "clientName":
                        <td style={{ width: "15rem", wordWrap: "anywhere" }}>{order.clientName}</td>,
                    "createdAt":
                        <td className="text-center">
                            {(new Date(order.createdAt)).toLocaleString('es-CL')}
                        </td>,
                    "updatedAt":
                        <td className="text-center">
                            {(new Date(order.updatedAt)).toLocaleString('es-CL')}
                        </td>,
                    "shippingType":
                        <td className="text-center">
                            {order.shippingType}
                            {order.origin === "PrestaShop" && (order.status === "packing" || order.status === "manifest")
                                ? <div className="pt-2">
                                    <ShippingLabel
                                        orderId={order._id}
                                    />
                                </div>
                                : null
                            }
                            {order.extraData &&
                                order.extraData.shipping &&
                                order.extraData.shipping.substatus === "buffered"
                                ? <Badge variant="info">
                                    PACKING {getExpectedPackingDate(order)}
                                </Badge>
                                : null
                            }
                        </td>,
                    "partialStockStatus":
                        <td className="text-center">
                            {order.partialStockStatus}
                            {order.partialStockStatusHistory && order.partialStockStatusHistory.length
                                ? <div className="pt-2">
                                    <StatusHistory
                                        statusHistory={order.partialStockStatusHistory}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "status":
                        <td className="text-center">
                            {order.status}
                            {order.statusHistory && order.statusHistory.length
                                ? <div className="pt-2">
                                    <StatusHistory
                                        statusHistory={order.statusHistory}
                                    />
                                </div>
                                : null
                            }
                        </td>,
                    "brand":
                        <td>{order.brand}</td>,
                    "items":
                        <td style={{ width: "35%" }}>
                            <ul style={{ paddingLeft: "1rem", listStyleType: "square" }}>
                                {order.items.map((item) => (
                                    <li key={item._id}>
                                        <Badge variant="info">{item.quantity}</Badge>{item.quantityRefunded ? <Badge style={{ marginLeft: "2px" }} variant="danger">-{item.quantityRefunded}</Badge> : null} {item.name}

                                        {item.quantityPacked || item.quantityOutOfStock || item.quantityDamaged
                                            ? <OrderItemPackingStatus
                                                item={item}
                                            />
                                            : null}
                                    </li>
                                ))}
                            </ul>
                        </td>,
                    "totalAmount":
                        <td>
                            <NumberFormat displayType={'text'} decimalSeparator="," value={order.totalAmount} thousandSeparator="." prefix={'$'} />
                        </td>,
                    "invoiceSentAt":
                        <td className="text-center" style={{ width: "6rem", wordWrap: "anywhere" }}>
                            {order.invoiceSentAt && order.invoiceSentAt !== "-"
                                ? <div>
                                    SI
                                    <div className="pt-2">
                                        <Receipt
                                            orderId={order._id}
                                        />
                                    </div>
                                </div>
                                : "NO"}
                        </td>,
                    "creditNoteCreatedAt":
                        <td className="text-center">
                            {order.creditNoteCreatedAt
                                ? <div>
                                    {(new Date(order.creditNoteCreatedAt)).toLocaleString('es-CL')}<br />
                                    <Badge variant="info">{order.creditNoteNumber}</Badge><br />
                                    <div className="pt-2">
                                        <CreditNote
                                            orderId={order._id}
                                        />
                                    </div>
                                </div>
                                : "NO"}
                        </td>,
                }[field]
            }
        </Fragment>
    );
}

export default OrderField;