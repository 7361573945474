import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from "react-bootstrap/Badge";
import { FaBan } from "react-icons/fa";

const CreditNoteItemsModalHeader = ({order, returnedItems, creditNoteReason, handleCancel, setReturnedItemsInOrder, infoMessageData}) => {
    const returnedItemsQuantity = returnedItems? returnedItems.reduce((a,b) => a + b.returned, 0) : 0;

    return (
        <Modal.Header>
            <Modal.Title>
                CONTENIDO DE LA NOTA DE CRÉDITO
            </Modal.Title>
            <div>
                <Button
                    variant="danger"
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={handleCancel}
                >
                    <FaBan style={{ marginBottom: "3px" }} />
                </Button>
                <Button
                    className="float-right mt-1 mb-2"
                    variant="success"
                    disabled={(!returnedItemsQuantity) || (infoMessageData && infoMessageData.type)}
                    onClick={() => setReturnedItemsInOrder(order, returnedItems, creditNoteReason)}
                >
                    Generar nota de crédito

                    <Badge className="ml-2" variant="light">
                        {returnedItemsQuantity} ítems
                    </Badge>
                </Button>
            </div>
        </Modal.Header>
    );
}
 
export default CreditNoteItemsModalHeader;