import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { FaSync } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";
import OrderTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import { filterGroupToQuery, filterDateToQuery } from "../../helpers/Query";
import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { getDateLimitedOrderFiltersSchema, creditNoteDateLimitedFilterSchema } from "../../helpers/Filters";

const CancelledOrders = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [procesingRefund, setProcesingRefund] = useState(false);
    const [procesingDownload, setProcesingDownload] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const [orderFilters, setOrderFilters] = useState({
        ...getDateLimitedOrderFiltersSchema(salesShopGroup),
        ...creditNoteDateLimitedFilterSchema,
        shippingTypes: undefined,
        statuses: [
            { id: "cancelled", name: "cancelled", value: true },
            { id: "refunded", name: "refunded", value: false },
        ],
        groups: ["statuses", "brands", "shopGroups"],
    });

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const customOrderFields = [
        { name: "Tienda", id: "shop" },
        { name: "Número", id: "idShop" },
        { name: "Cliente", id: "clientName" },
        { name: "Fecha creación", id: "createdAt" },
        { name: "Fecha nota crédito", id: "creditNoteCreatedAt" },
        { name: "Tipo envío", id: "shippingType" },
        { name: "Estado", id: "status" },
        { name: "Marca", id: "brand" },
        { name: "Ítems", id: "items" },
        { name: "Total", id: "totalAmount" },
        { name: "Boleta enviada", id: "invoiceSentAt" },
    ];

    const handleOrderSelect = (orderId, checked) => {
        //console.log("Cambia item ", orderId, " checked ", checked);

        if (checked) {
            setSelectedOrders((oldSelectedOrders) => {
                if (oldSelectedOrders.includes(orderId)) {
                    return oldSelectedOrders;
                } else {
                    return [...oldSelectedOrders, orderId];
                }
            });
        } else {
            setSelectedOrders((oldSelectedOrders) =>
                oldSelectedOrders.filter((id) => id !== orderId)
            );
        }
    };

    const fetchOrders = useCallback(() => {
        setOrdersLoading(true);

        let queryFilters = "?group=cancelled&";
        queryFilters += (filterGroupToQuery("status", orderFilters.statuses));
        queryFilters += filterGroupToQuery("brand", orderFilters.brands);
        /*queryFilters += filterGroupToQuery(
            "shippingType",
            orderFilters.shippingTypes
        );*/
        queryFilters += filterDateToQuery("date", orderFilters.date);
        queryFilters += filterDateToQuery("creditNoteDate", orderFilters.creditNoteDate);
        queryFilters += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + queryFilters, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
                //setSelectedOrders(ordersResponse.data.map( order => order._id));
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    const handleRefund = () => {
        setProcesingRefund(true);

        const refundsRequest = {
            selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
        };

        console.log("Handling refund for: ", refundsRequest.selectedOrders);

        axios
            .post(env.webApiBaseUrl + "/orders/refund", refundsRequest, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((refundsResponse) => {
                const updatedOrders = refundsResponse.data.updatedOrders;
                setInfoModalData({
                    type: "success",
                    msg: updatedOrders + " venta(s) marcadas como refunded.",
                });

                setSelectedOrders([]);
                fetchOrders();
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude generar el refund.",
                });
            })
            .finally(() => {
                setProcesingRefund(false);
            });
    };

    const handleDownload = useCallback(
        () => {
            setProcesingDownload(true);
    
            const reportRequest = {
                selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
            };
    
            console.log("Generating returns report for: ", reportRequest.selectedOrders);
    
            axios
                .post(env.webApiBaseUrl + "/orders/returns", reportRequest, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                        responseType: 'blob',
                    },
                    withCredentials: true,
                })
                .then((reportResponse) => {
                    const fileContent = reportResponse.data;

                    const filename = "canceladas-" + Date.now() + ".csv";
                    fileDownload(fileContent, filename, "text/csv", "\uFEFF");
    
                    setSelectedOrders([]);
                    fetchOrders();
                })
                .catch((error) => {
                    console.error(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude generar el reporte.",
                    });
                })
                .finally(() => {
                    setProcesingDownload(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, items, selectedOrders, user.token],
    );

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        const cleanSelectedOrders = () => {
            // Revisar que todo lo que está en selectedOrders exista
        };

        cleanSelectedOrders();
    }, [orders]);

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>
                
                <Button
                    variant="primary"
                    className="float-right mt-1 mb-2 ml-2"
                    disabled={!selectedOrders.length || procesingDownload}
                    onClick={() => handleDownload()}
                >
                    Descargar reporte ventas canceladas
                    {procesingDownload ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>

                <Button
                    variant="dark"
                    className="float-right mt-1 mb-2"
                    disabled={!selectedOrders.length || procesingRefund}
                    onClick={() => handleRefund()}
                >
                    Marcar como refunded
                    {procesingRefund ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>

                <h2>
                    VENTAS CANCELADAS
                    <span className="quantities">
                        <Badge className="ml-3" variant="dark">
                            {orders.length} ventas
                        </Badge>
                        <Badge className="ml-2" variant="info">
                            {itemQuantity} ítems
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={true}
                        type={infoModalData.type}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) : null}

                <OrderTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    customFields={customOrderFields}
                    ordersLoading={ordersLoading}
                />
            </Container>
        </div>
    );
};

export default CancelledOrders;
