import React, { useRef, useEffect, useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const CreditNoteForm = ({
    itemSku,
    validated,
    handleChange,
    handleSubmit,
    creditNoteReason,
    setCreditNoteReason,
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const focus = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                //console.log("Input focus", inputRef.current.value);
            }
        };
        setTimeout(focus, 100);
    }, []);

    const [key, setKey] = useState("returned");

    const handleCheckReason = useCallback((event) => {
        if (event.target.checked) {
            //console.log("Reason checked:", event.target.value);
            setCreditNoteReason(event.target.value);
        }
    }, [setCreditNoteReason]);

    return (
        <Container style={{ minHeight: "8rem" }}>
            <Form
            >
                <Row>
                    <Col className="text-right">
                        <Form.Label>Motivo de la nota de crédito</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right pr-0">
                        <Form.Check 
                            className="ml-3"
                            inline
                            custom
                            name="cnReason"
                            type="radio"
                            id="inputReasonReturn"
                            defaultChecked={creditNoteReason === "client-return"}
                            label="Devolución"
                            value="client-return"
                            onChange={(event) => handleCheckReason(event)}
                        />
                        <Form.Check 
                            className="ml-3"
                            inline
                            custom
                            name="cnReason"
                            type="radio"
                            id="inputReasonDispatchError"
                            defaultChecked={creditNoteReason === "dispatch-error"}
                            label="Error de despacho"
                            value="dispatch-error"
                            onChange={(event) => handleCheckReason(event)}
                        />
                        <Form.Check 
                            className="ml-3"
                            inline
                            custom
                            name="cnReason"
                            type="radio"
                            id="inputReasonCancel"
                            defaultChecked={creditNoteReason === "client-cancel"}
                            label="Cancelación"
                            value="client-cancel"
                            onChange={(event) => handleCheckReason(event)}
                        />
                    </Col>
                </Row>
            </Form>
            <br />
            <Form
                className="h-100"
                validated={validated}
                onSubmit={(event) => handleSubmit(event, "returned")}
            >
                <Tabs
                    id="returnItemsTabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="returned" title="Agregar ítem devuelto">
                        <div className="w-100 h-100 p-4 inputItemForm">
                            <Form.Row>
                                <Col>
                                    <Form.Label>Agregar ítem</Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group
                                    as={Col}
                                    sm={10}
                                    controlId="inputItemSku"
                                >
                                    <Form.Control
                                        required
                                        ref={inputRef}
                                        name="itemSku"
                                        type="text"
                                        placeholder="SKU"
                                        value={itemSku}
                                        onChange={(event) =>
                                            handleChange(event, "returned")
                                        }
                                        maxLength={24}
                                    />
                                </Form.Group>
                                <Col sm={2}>
                                    <Button
                                        block
                                        type="submit"
                                        variant="primary"
                                    >
                                        Agregar
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </Tab>
                </Tabs>
            </Form>
        </Container>
    );
};

export default CreditNoteForm;
