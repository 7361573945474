import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { SwitchTransition, CSSTransition } from "react-transition-group";

import LoadingSpinner from './LoadingSpinner';
import Alert from './Alert';

import CheckImg from '../../assets/check.png';

const InfoMessage = ({type, closeButton, retryButton, msg, setMsg, ok, cancel}) => {
    let icon;
    switch (type) {
        case 'loading':
            icon = (
                <LoadingSpinner />
            );
            break;
        case 'success':
            icon = <img src={CheckImg} width="100" height="100" alt="OK" />;
            break;
        default:
            icon = <Alert />;
    }

    const closeButtonText = retryButton ? 'REINTENTAR' : 'CERRAR';

    return (
        <Fragment>
            <SwitchTransition>
                <CSSTransition key={type} classNames="wrk-alert" timeout={300}>
                    <div className="wrk-icon mt-4 mb-4">
                        {icon}
                    </div>
                </CSSTransition>
            </SwitchTransition>

            <SwitchTransition>
                <CSSTransition key={msg} classNames="wrk-alert" timeout={300}>
                <div>
                    {type === "prompt"
                    ? <Container>
                        <p>
                            {msg}
                        </p>
                        <span>
                            <Button 
                                variant="primary" 
                                block 
                                onClick={ok}
                            >
                                OK
                            </Button>
                            <Button 
                                variant="danger" 
                                block 
                                onClick={cancel}
                            >
                                CANCELAR
                            </Button>
                        </span>
                    </Container>
                    : <Fragment>
                        { closeButton || retryButton
                        ? (
                            <Container>
                                <p>
                                    {msg}
                                </p>
                                <Button 
                                    variant="primary" 
                                    block 
                                    onClick={ () => setMsg({}) }
                                >
                                    {closeButtonText}
                                </Button>
                            </Container>
                        )
                        : (
                            <p>{msg}</p>
                        )
                        }
                    </Fragment>
                    }
                </div>       
                </CSSTransition>
            </SwitchTransition>
        </Fragment>
    );
}
 
export default InfoMessage;