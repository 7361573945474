import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Container from 'react-bootstrap/Container';

import Home from '../Home/Home';
import Pickings from '../Pickings/Pickings';
import Processed from '../Processed/Processed';
import Packings from '../Packings/Packings';
import Dispatchs from '../Dispatchs/Dispatchs';
import Returns from '../Returns/Returns';
import Cancelled from '../Cancelled/Cancelled';
import Incomplete from '../Incomplete/Incomplete';
import Tools from '../Tools/Tools';

import GenericNotFound from './GenericNotFound';

const AnimatedPage = withRouter(({ location }) => {
    return (
      <Container fluid className="siteContent m-0 p-0">
        <SwitchTransition>
          <CSSTransition 
            key={location.key} 
            classNames="changePage" 
            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
          >

            <Switch location={location}>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/pickings" component={Pickings} />
                <Route exact path="/packings" component={Packings} />
                <Route exact path="/dispatchs" component={Dispatchs} />
                <Route exact path="/processed" component={Processed} />
                <Route exact path="/returned" component={Returns} />
                <Route exact path="/cancelled" component={Cancelled} />
                <Route exact path="/incomplete" component={Incomplete} />
                <Route exact path="/tools" component={Tools} />

                <Route component={GenericNotFound} />
            </Switch>

        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
});

export default AnimatedPage;