import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import { FaPlayCircle } from "react-icons/fa";

import { UserContext } from '../../context/UserContext';
import { SiteContext } from "../../context/SiteContext";

const Home = () => {
    const { user } = useContext(UserContext);
    const { setSalesShopGroup } = useContext(SiteContext);

    const history = useHistory();

    const start = (shopGroup) => {
        setSalesShopGroup(shopGroup);
        history.push("/pickings");
    };

    return (
        <Container>
            <br />
            <br />
            <Row className="justify-content-end">
                <Col lg={6}>
                    <Jumbotron className="jumboDesktop">
                        <h1>
                            <b>Bienvenido al
                            Backoffice Logístico</b>
                        </h1>
                        <br/>
                        <p className="lead">
                            Ingresaste al sistema como: <br/>
                            <em><strong>{user.email}</strong></em>
                        </p>
                        <br/>
                        <Row className="homeMenu">
                            <Col xs={12}>
                                <Button
                                    variant="light"
                                    block
                                    onClick={() => start("MercadoLibre")}
                                >
                                    <FaPlayCircle className="mr-2" style={{ marginBottom: "1px" }}/>
                                    <span className="">VENTAS ML</span>
                                </Button>
                            </Col>
                            <Col xs={12}>
                                <Button
                                    variant="light"
                                    block
                                    onClick={() => start("Ripley")}
                                >
                                    <FaPlayCircle className="mr-2" style={{ marginBottom: "1px" }}/>
                                    <span className="">VENTAS RIPLEY</span>
                                </Button>
                            </Col>
                            <Col xs={12}>
                                <Button
                                    variant="light"
                                    block
                                    onClick={() => start("paris.cl")}
                                >
                                    <FaPlayCircle className="mr-2" style={{ marginBottom: "1px" }}/>
                                    <span className="">VENTAS PARIS</span>
                                </Button>
                            </Col>
                        </Row>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;