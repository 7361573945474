import React from 'react';
import Table from 'react-bootstrap/Table';

import ItemsStatus from './ItemsStatus';

import { getProductosFromDocumentedSale } from '../../helpers/DocumentedSale';

const ItemsTable = ({documentedSale, returnedItems}) => {
    if (!documentedSale) {
        return null;
    }

    return (
        <Table bordered hover>
            <thead>
                <tr>
                    <th>
                        SKU
                    </th>
                    <th>
                        Producto <br />en boleta
                    </th>
                    <th>
                        Cantidad <br />en boleta
                    </th>
                    <th>
                        Estado
                    </th>
                </tr>
            </thead>
            <tbody>
                {getProductosFromDocumentedSale(documentedSale).ventaItemListaDatosList.map((item, index) => (
                    <tr key={"item_" + index}>
                        <td style={{ width: "14rem" }}>
                            {item.sku}<br />
                        </td>
                        <td>
                            {item.descripcion}
                        </td>
                        <td style={{ width: "6rem" }}>
                            {item.cantidad || 0}{item.cantidadDevol ? " (-" + item.cantidadDevol + ")" : null}
                        </td>
                        <td className="h3">
                            <ItemsStatus
                                itemQuantity={item.cantidad - item.cantidadDevol || 0}
                                itemQuantityReturnedOk={(returnedItems.find((returnedItem) => returnedItem.sku === item.sku).returned) || 0}
                                itemQuantityReturnedDamaged={(returnedItems.find((returnedItem) => returnedItem.sku === item.sku).returnedDamaged) || 0}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
 
export default ItemsTable;