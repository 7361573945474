import React from 'react';
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { FaBan } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";

const PackingsSubHeader = ({salesShopGroup, orderQuantity, itemQuantity, selectedOrderQuantity, printingPacking, clearOrders, handlePrintPackings}) => {
    return (  
        <SubHeader>
            <Button
                variant="danger"
                className="float-right ml-2 mt-1 mb-2"
                onClick={clearOrders}
            >
                <FaBan style={{ marginBottom: "3px" }} />
            </Button>
            <Button
                className="float-right mt-1 mb-2"
                variant="warning"
                disabled={!selectedOrderQuantity || printingPacking}
                onClick={handlePrintPackings}
            >
                Descargar etiquetas para packing

                <Badge className="ml-2" variant="light">
                    {selectedOrderQuantity}
                </Badge>
            </Button>
            <h2>
                VENTAS PARA PACKING
                <span className="quantities">
                    <Badge className="ml-3" variant="dark">
                        {orderQuantity} ventas
                    </Badge>
                    <Badge className="ml-2" variant="info">
                        {itemQuantity} ítems
                    </Badge>
                </span>
            </h2>
            <strong>Origen:</strong> {salesShopGroup}
        </SubHeader>
    );
}
 
export default PackingsSubHeader;