import React, { useCallback } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import OrderRow from "./OrderRow";
import ColumnHeader from "./ColumnHeader";
import OrderFilters from "./OrderFilters";
import LoadingSpinner from "../Layout/LoadingSpinner";

const OrdersTable = ({
    orders,
    sortConfig,
    requestSort,
    selectedOrders,
    handleOrderSelect,
    orderFilters,
    setOrderFilters,
    ordersLoading,
    vistaSinStock,
    customFields,
    customActionsFunction
}) => {
    const handleSelectAllOrders = useCallback((orders) => {
        const selected = selectedOrders || [];

        if (handleOrderSelect) {
            if (selected.length === orders.length) {
                orders.forEach((order) => {
                    handleOrderSelect(order._id, false);
                });
            } else {
                orders.forEach((order) => {
                    handleOrderSelect(order._id, true);
                });
            }
        }
    }, [handleOrderSelect, selectedOrders]);

    const fields = customFields
        ? customFields
        : (
            vistaSinStock
                ? [
                    { name: "Tienda", id: "shop" },
                    { name: "Número", id: "idShop" },
                    { name: "Cliente", id: "clientName" },
                    { name: "Fecha creación", id: "createdAt" },
                    { name: "Tipo envío", id: "shippingType" },
                    { name: "Estado faltantes", id: "partialStockStatus" },
                    { name: "Estado", id: "status" },
                    { name: "Marca", id: "brand" },
                    { name: "Ítems", id: "items" },
                    { name: "Total", id: "totalAmount" },
                    { name: "Boleta enviada", id: "invoiceSentAt" },
                ]
                : [
                    { name: "Tienda", id: "shop" },
                    { name: "Número", id: "idShop" },
                    { name: "Cliente", id: "clientName" },
                    { name: "Fecha creación", id: "createdAt" },
                    { name: "Fecha update", id: "updatedAt" },
                    { name: "Tipo envío", id: "shippingType" },
                    { name: "Estado", id: "status" },
                    { name: "Marca", id: "brand" },
                    { name: "Ítems", id: "items" },
                    { name: "Total", id: "totalAmount" },
                    { name: "Boleta enviada", id: "invoiceSentAt" },
                ]
        );

    return (
        <Container fluid>
            {orderFilters ? (
                <OrderFilters
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                />
            ) : null}
            <br />

            {ordersLoading
                ? <div className="text-center">
                    <br />
                    <br />
                    <LoadingSpinner />
                </div>
                : <Table striped bordered hover>
                    {/*console.log("Rendering order table")*/}
                    <thead>
                        <tr>
                            {handleOrderSelect ? (
                                <th>
                                    <Form.Check
                                        id={"all_items"}
                                        custom
                                        type="checkbox"
                                        checked={
                                            selectedOrders.length === orders.length
                                        }
                                        onChange={() =>
                                            handleSelectAllOrders(orders)
                                        }
                                    />
                                </th>
                            ) : null}

                            {customActionsFunction ? (
                                <th>
                                    Acciones
                                </th>
                            ) : null}

                            {fields.map((header) => (
                                <ColumnHeader
                                    key={"header_" + header.id}
                                    name={header.name}
                                    id={header.id}
                                    sortConfig={sortConfig}
                                    requestSort={requestSort}
                                    vistaSinStock={vistaSinStock}
                                />
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {orders.map((order) => {
                            const selected = selectedOrders
                                ? selectedOrders.includes(order._id)
                                    ? true
                                    : false
                                : null;

                            return (
                                <OrderRow
                                    key={order._id}
                                    order={order}
                                    selected={selected}
                                    handleOrderSelect={handleOrderSelect}
                                    vistaSinStock={vistaSinStock}
                                    fields={fields}
                                    customActionsFunction={customActionsFunction}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            }

        </Container>
    );
};

export default OrdersTable;
