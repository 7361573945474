import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import fileDownload from "js-file-download";
import { FaSync } from "react-icons/fa";
import { PDFDocument, StandardFonts, PageSizes } from "pdf-lib";

import SubHeader from "../Layout/SubHeader";
import OrderTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";
import CancelOrdersButton from "../Orders/CancelOrdersButton";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import {
    filterGroupToQuery,
    filterDateToQuery,
    chunks,
} from "../../helpers/Query";
import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { getDefaultOrderFiltersSchema } from "../../helpers/Filters";

const DispatchOrders = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [printingManifest, setPrintingManifest] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const [orderFilters, setOrderFilters] = useState({
        ...getDefaultOrderFiltersSchema(salesShopGroup),
        statuses: [
            { id: "packing", name: "packing", value: true },
            { id: "manifest", name: "manifest", value: false },
        ],
    });

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const handleOrderSelect = useCallback(
        (orderId, checked) => {
            //console.log("Cambia item ", orderId, " checked ", checked);
    
            if (checked) {
                setSelectedOrders((oldSelectedOrders) => {
                    if (oldSelectedOrders.includes(orderId)) {
                        return oldSelectedOrders;
                    } else {
                        return [...oldSelectedOrders, orderId];
                    }
                });
            } else {
                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter((id) => id !== orderId)
                );
            }
        },
        [],
    );

    const fetchOrders = useCallback(() => {
        setOrdersLoading(true);

        let queryFilters = "?";
        queryFilters +=
            filterGroupToQuery("status", orderFilters.statuses) ||
            "status=packing&status=manifest";
        queryFilters += filterGroupToQuery("brand", orderFilters.brands);
        queryFilters += filterGroupToQuery(
            "shippingType",
            orderFilters.shippingTypes
        );
        queryFilters += filterDateToQuery("date", orderFilters.date);
        queryFilters += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + queryFilters, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
                setSelectedOrders(
                    ordersResponse.data.map((order) => order._id)
                );
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    const handlePrintManifestsSummary = async (
        processedOrders,
        processedItemQuantity
    ) => {
        try {
            const pdfDoc = await PDFDocument.create();
            const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

            const page = pdfDoc.addPage();
            page.setFont(font);
            const { height } = page.getSize();
            const fontSize = 14;

            page.drawText("RESUMEN DE LOS MANIFIESTOS\n\n", {
                x: 45,
                y: height - 5 * fontSize,
                size: fontSize + 4,
            });

            page.drawText(
                "Ventas:\t\t" +
                    processedOrders +
                    "\nUnidades:\t" +
                    processedItemQuantity,
                {
                    x: 45,
                    y: height - 8 * fontSize,
                    size: fontSize,
                }
            );

            const pdfBytes = await pdfDoc.save();
            const filename = "manifestSummary-" + Date.now() + ".pdf";
            fileDownload(pdfBytes, filename);
        } catch (e) {
            console.error("Problemas al generar resumen del manifesto: ", e);
        }
    };

    const addSummaryToManifest = useCallback(
        async (
        pdf,
        processedOrders,
        processedItemQuantity
    ) => {
        try {
            const pdfDoc = await PDFDocument.load(pdf);
            const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

            const page = pdfDoc.addPage(PageSizes.Letter);
            page.setFont(font);
            const { height } = page.getSize();
            const fontSize = 14;

            page.drawText("RESUMEN DEL MANIFIESTO\n\n", {
                x: 45,
                y: height - 5 * fontSize,
                size: fontSize + 4,
            });

            page.drawText(
                "Ventas:\t\t" +
                    processedOrders +
                    "\nUnidades:\t" +
                    processedItemQuantity,
                {
                    x: 45,
                    y: height - 8 * fontSize,
                    size: fontSize,
                }
            );

            const pdfExtended = await pdfDoc.saveAsBase64();
            return pdfExtended;
        } catch (e) {
            console.error("Problemas al agregar resumen al manifesto: ", e);
            return pdf;
        }
    }, []);

    const concatManifest = useCallback(
        async (pdfManifest, pdfAddedManifest) => {
            try {
                //console.log("Merging manifests pdfs:", pdfManifest, pdfAddedManifest);

                const pdf1 = pdfManifest ? await PDFDocument.load(pdfManifest) : null;
                const pdf2 = await PDFDocument.load(pdfAddedManifest);
    
                const mergedPdf = await PDFDocument.create();
    
                if (pdf1) {
                    const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
                    copiedPagesA.forEach((page) => mergedPdf.addPage(page));
                }
    
                const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
                copiedPagesB.forEach((page) => mergedPdf.addPage(page));
    
                const pdfBase64Extended = await mergedPdf.saveAsBase64();
                return pdfBase64Extended;
            } catch (e) {
                console.error("Problemas al concatenar manifestos: ", e);
                return pdfManifest;
            }
        },
        [],
    );

    const handlePrintManifestForChunk = useCallback(
        async (selectedOrdersChunk, downloadChunk) => {
            const manifestsRequest = {
                selectedOrders: selectedOrdersChunk,
            };
    
            console.log("Printing manifest for: ", manifestsRequest.selectedOrders);
    
            let summary;
            let pdfContentBase64;
            try {
                const manifestsResponse = await axios.post(
                    env.webApiBaseUrl + "/orders/manifest",
                    manifestsRequest,
                    {
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRF-Token": csrfToken,
                            Authorization: "Bearer " + user.token,
                        },
                        withCredentials: true,
                        timeout: 60000,
                    }
                );
                
                //const filename = manifestsResponse.data.name;
                summary = manifestsResponse.data.summary;
                pdfContentBase64 = manifestsResponse.data.pdfContent;

                if (downloadChunk) {
                    const pdfBuff = Buffer.from(pdfContentBase64, "base64");
                    const filename = "manifest-" + Date.now() + ".pdf";
                    fileDownload(pdfBuff, filename);
                }
    
                setSelectedOrders((oldSelectedOrders) =>
                    oldSelectedOrders.filter(
                        (order) => !selectedOrdersChunk.includes(order)
                    )
                );
    
                fetchOrders();
            } catch (e) {
                console.error("Problemas al descargar manifesto para grupo: ", e);
                throw e;
            }
    
            return {
                pdfContentBase64: pdfContentBase64,
                summary: summary,
            };
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, user.token],
    );

    const handlePrintManifest = useCallback(
        async () => {
            setPrintingManifest(true);

            const downloadChunks = salesShopGroup === "MercadoLibre" ? true : false;
        
            let documentBase64;
            let processedOrders = 0;
            let processedItemQuantity = 0;
            try {
                const selectedOrdersChunks = chunks(
                    sortSeletedOrdersFromSortedOrders(selectedOrders, items),
                    env.downloadMaxChunkSize
                );
    
                let chunkNumber = 1;
                for (const selectedOrdersChunk of selectedOrdersChunks) {
                    setInfoModalData({
                        type: "loading",
                        msg:
                            "Generando los manifestos para el grupo " +
                            chunkNumber +
                            " de " +
                            selectedOrdersChunks.length,
                        closeButton: false,
                    });
    
                    const manifestResponse = await handlePrintManifestForChunk(
                        selectedOrdersChunk,
                        downloadChunks
                    );
    
                    if (!downloadChunks && manifestResponse.pdfContentBase64) {
                        documentBase64 = documentBase64 ? await concatManifest(documentBase64, manifestResponse.pdfContentBase64) : manifestResponse.pdfContentBase64;
                    }
    
                    const chunkSummary = manifestResponse.summary;
                    if (chunkSummary) {
                        processedOrders += chunkSummary.processedOrders;
                        processedItemQuantity += chunkSummary.processedItemQuantity;
                    }
    
                    chunkNumber++;
                }
    
                setInfoModalData(null);
    
                console.log(
                    "ProcessedOrders: ",
                    processedOrders,
                    ", processedItemQuantity: ",
                    processedItemQuantity
                );
                
                if (downloadChunks) {
                    handlePrintManifestsSummary(processedOrders, processedItemQuantity);
                }
            } catch (e) {
                console.error("Error al generar los manifestos:", e);
    
                setInfoModalData({
                    type: "error",
                    msg: "Problemas al generar los manifiestos.",
                });
            }

            if (!downloadChunks) {
                try {
                    if (documentBase64) {
                        documentBase64 = await addSummaryToManifest(documentBase64, processedOrders, processedItemQuantity);

                        const filename = "manifest-" + Date.now() + ".pdf";
        
                        const pdfBuff = Buffer.from(documentBase64, "base64");
                        fileDownload(pdfBuff, filename);
                    }
                } catch(e) {
                    console.error("Error al agregar el resumen de los manifestos: ", e);

                    setInfoModalData({
                        type: "error",
                        msg: "Problemas al generar los manifiestos.",
                    });
                }
            }

            setPrintingManifest(false);
        },
        [addSummaryToManifest, concatManifest, env.downloadMaxChunkSize, handlePrintManifestForChunk, items, salesShopGroup, selectedOrders],
    );

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        const cleanSelectedOrders = () => {
            // Revisar que todo lo que está en selectedOrders exista
        };

        cleanSelectedOrders();
    }, [orders]);

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>
                <Button
                    variant="success"
                    className="float-right mt-1 mb-2 ml-2"
                    disabled={!selectedOrders.length || printingManifest}
                    onClick={() => handlePrintManifest()}
                >
                    Descargar manifiesto para despacho
                    {false ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
                <CancelOrdersButton
                    items={items}
                    selectedOrders={selectedOrders}
                    setInfoModalData={setInfoModalData}
                    setSelectedOrders={setSelectedOrders}
                    fetchOrders={fetchOrders}
                />

                <h2>
                    VENTAS PARA DESPACHO
                    <span className="quantities">
                        <Badge className="ml-3" variant="dark">
                            {orders.length} ventas
                        </Badge>
                        <Badge className="ml-2" variant="info">
                            {itemQuantity} ítems
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={
                            infoModalData.closeButton !== undefined
                                ? infoModalData.closeButton
                                : true
                        }
                        type={infoModalData.type}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) : null}

                <OrderTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    ordersLoading={ordersLoading}
                />
            </Container>
        </div>
    );
};

export default DispatchOrders;
