import React, { Fragment, useCallback, useContext, useState } from 'react';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const CancelOrdersButton = ({items, selectedOrders, setInfoModalData, setSelectedOrders, fetchOrders, isPartialOrder}) => {
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [processingCancel, setProcessingCancel] = useState(false);

    const handleCancel = useCallback(
        () => {
            setProcessingCancel(true);

            const cancelRequest = {
                selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
            };
    
            console.log("Handling cancellation for: ", cancelRequest.selectedOrders);
    
            const url = isPartialOrder ? env.webApiBaseUrl + "/orders/partials/cancel" : env.webApiBaseUrl + "/orders/cancel";

            axios
                .post(url, cancelRequest, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((cancelResponse) => {
                    const updatedOrders = cancelResponse.data.updatedOrders;
                    const ventaWord = updatedOrders === 1 ? " venta marcada " : " ventas marcadas ";

                    setInfoModalData({
                        type: "success",
                        msg: updatedOrders + ventaWord + "como cancelled.",
                    });
    
                    setSelectedOrders([]);
                    fetchOrders();
                })
                .catch((error) => {
                    console.error(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude aplicar las cancelaciones.",
                    });
                })
                .finally(() => {
                    setProcessingCancel(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, items, selectedOrders, setInfoModalData, setSelectedOrders, user.token, isPartialOrder],
    );

    return (
        <Button
                    variant="danger"
                    className="float-right mt-1 mb-2 ml-2"
                    disabled={!selectedOrders.length || processingCancel}
                    onClick={() => handleCancel()}
                >
                    Cancelar ventas
                    {processingCancel ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
    );
}
 
export default CancelOrdersButton;
