import React, { Fragment, useCallback } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const CreditNoteCreditNoteDateRangeFilter = ({
    filters,
    setFilters,
    creditNoteDateRangeOptions,
}) => {
    const handleCreditNoteDateRange = useCallback(
        (range) => {
            //console.log("Updating filter date range: ", range);

            setFilters((oldFilters) => {
                const newFilters = {
                    ...oldFilters,
                    creditNoteDate: {
                        ...oldFilters.creditNoteDate,
                        range: range,
                    },
                };
                //console.log("New dates: ", newDates);

                return newFilters;
            });
        },
        [setFilters]
    );

    const handleCustomDate = useCallback(
        (customDate, boundary) => {
            if (!customDate) return;

            //console.log("Setting custom date: ", customDate, boundary);

            try {
                const tzOffset = new Date().getTimezoneOffset();

                const dateFrom = new Date(
                    Date.parse(customDate + "T00:00:00.000Z")
                );
                dateFrom.setMinutes(dateFrom.getMinutes() + tzOffset);

                const dateUntil = new Date(customDate + "T23:59:59.999Z");
                dateUntil.setMinutes(dateUntil.getMinutes() + tzOffset);

                const dateFromString = dateFrom.toISOString();
                const dateUntilString = dateUntil.toISOString();

                setFilters((oldFilters) => {
                    const newFilters = {
                        ...oldFilters,
                        creditNoteDate: {
                            ...oldFilters.creditNoteDate,
                            from:
                                !boundary || boundary === "from"
                                    ? dateFromString
                                    : oldFilters.creditNoteDate.from,
                            until:
                                !boundary || boundary === "until"
                                    ? dateUntilString
                                    : oldFilters.creditNoteDate.until,
                        },
                    };
                    //console.log("New filters: ", newFilters);

                    return newFilters;
                });
            } catch (e) {}
        },
        [setFilters]
    );

    return (
        <Form.Group>
            <Form.Label>Fecha nota de crédito</Form.Label>

            <Form.Control
                id="filterCreditNoteDate"
                as="select"
                className=""
                value={filters.creditNoteDate.range}
                onChange={(event) =>
                    handleCreditNoteDateRange(event.currentTarget.value)
                }
                custom
            >
                {creditNoteDateRangeOptions.map((option) => (
                    <option key={"option_" + option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </Form.Control>

            {filters.creditNoteDate && filters.creditNoteDate.range ? (
                <Fragment>
                    {
                        {
                            custom: (
                                <Form.Control
                                    className="mt-4"
                                    id="customCreditNoteDate"
                                    type="date"
                                    required
                                    onChange={(event) =>
                                        handleCustomDate(event.target.value)
                                    }
                                />
                            ),
                            range: (
                                <span>
                                    <InputGroup className="mt-1">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                style={{ width: "2.5rem" }}
                                            >
                                                De
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            className="mt-0"
                                            id="customCreditNoteDateFrom"
                                            type="date"
                                            required
                                            onChange={(event) =>
                                                handleCustomDate(
                                                    event.target.value,
                                                    "from"
                                                )
                                            }
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                style={{ width: "2.5rem" }}
                                            >
                                                A
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            className="mt-0"
                                            id="customCreditNoteDateTo"
                                            type="date"
                                            required
                                            onChange={(event) =>
                                                handleCustomDate(
                                                    event.target.value,
                                                    "until"
                                                )
                                            }
                                        />
                                    </InputGroup>
                                </span>
                            ),
                        }[filters.creditNoteDate.range]
                    }
                </Fragment>
            ) : null}
        </Form.Group>
    );
};

export default CreditNoteCreditNoteDateRangeFilter;
