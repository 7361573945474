const dev = {
    webApi: {
        BASE_URL: "http://localhost:9010/api",
        VERSION: "v1"
    },
    downloadMaxChunkSize: 30,
};

const beta = {
    webApi: {
        BASE_URL: "https://demo.api.backoffice.rebelsouls.io/api",
        VERSION: "v1"
    },
    downloadMaxChunkSize: 30,
};

const prod = {
    webApi: {
        BASE_URL: "https://api.backoffice.rebelsouls.io/api",
        VERSION: "v1"
    },
    downloadMaxChunkSize: 30,
};

let config;
switch ( process.env.REACT_APP_STAGE ) {
  case 'dev':
    config = dev;
    break;

  case 'beta':
    config = beta;
    break;

  default:
    config = prod;
}

const appConfig = {
  // Agregar acá valores comunes a todos los ambientes
  ...config
};

export default appConfig;
