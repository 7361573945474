import React, { Fragment, useCallback, useContext, useState } from "react";
import axios from "axios";

import OrderItemsModal from "./OrderItemsModal";
import AddOrderForm from "./AddOrderForm";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import { isBuffered, getExpectedPackingDate } from "../../helpers/Packing";
import { getShopGroupFromOrder } from "../../helpers/ShopGroups";

const AddOrder = ({
    orders,
    setOrders,
    setInfoModalData,
    handleOrderSelect,
}) => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [validated, setValidated] = useState(false);
    const [orderId, setOrderId] = useState("");

    const [orderLoading, setOrderLoading] = useState(false);
    const [packingOrder, setPackingOrder] = useState(null);

    const handleOrderPacked = useCallback(
        (order) => {
            // Cuando quedan en estado review ya no se agregan a la lista para etiquetas
            // pues se espera que se pistolee nuevamente una segunda vez
            if (order.status !== "review" || order.prevStatus === "review") {
                setOrders((oldOrders) => [...oldOrders, order]);
                handleOrderSelect(order._id, true);
            }

            setOrderId("");
            setValidated(false);
            setPackingOrder(null);
        },
        [handleOrderSelect, setOrders],
    );

    const handleOrderLoaded = useCallback(
        (order) => {
            if (!["picking", "packing", "review"].includes(order.status)) {
                setInfoModalData({
                    type: "error",
                    msg:
                        "La venta seleccionada está en estado '" +
                        order.status +
                        "'. No se puede imprimir etiqueta de packing.",
                });
            } else if (!order.items.reduce((x,y) => x + y.quantityToPack, 0)){
                setInfoModalData({
                    type: "error",
                    msg: "La venta no tiene ningún producto para packing.",
                });
            } else if (isBuffered(order)) {
                const packingDate = getExpectedPackingDate(order);
                setInfoModalData({
                    type: "error",
                    msg: "La venta no se puede procesar aún pues el shipping se encuentra BUFFERED. La fecha de liberación del shipping es el " + packingDate + ".",
                });
            } else if (getShopGroupFromOrder(order) !== salesShopGroup) {
                setInfoModalData({
                    type: "error",
                    msg: "La venta seleccionada es de un origen distinto a " + salesShopGroup + ", no se puede agregar a la lista.",
                });
            } else if (order.shippingType === "Otro") {
                setInfoModalData({
                    type: "error",
                    msg: "La venta seleccionada requiere de otro tipo de despacho.",
                });
            } else if (
                orders.length > 0 &&
                orders[0].shippingType &&
                orders[0].shippingType !== order.shippingType
            ) {
                setInfoModalData({
                    type: "error",
                    msg: "La venta seleccionada tiene un tipo de despacho distinto a la venta anterior, no se puede agregar a la lista.",
                });
            } else if (order.items.filter((item) => !item.variant).length && order.shop === "MercadoLibre") {
                setInfoModalData({
                    type: "alert",
                    msg: "La venta seleccionada tiene items sin información de SKU, se agregará directamente a la lista.",
                });

                handleOrderPacked(order);
            } else if (order.items.filter((item) => !item.variant).length) {
                setInfoModalData({
                    type: "alert",
                    msg: "La venta seleccionada tiene items sin información de SKU, no se puede agregar a la lista.",
                });
            } else if (order.status === "packing") {
                setInfoModalData({
                    type: "prompt",
                    msg: "Esta venta ya está en estado packing.\n¿Seguro que quieres continuar?",
                    ok: () => {setPackingOrder(order); setInfoModalData({})},
                    cancel: () => setInfoModalData({}),
                });
            } else {
                setPackingOrder(order);
            }
        },
        [handleOrderPacked, orders, salesShopGroup, setInfoModalData]
    );

    const handleAddOrder = useCallback(
        (id) => {
            setOrderLoading(true);

            // Para no agregar más de una vez cada órden
            const filteredOrders = orders.filter((order) => order._id === id);
            if (filteredOrders && filteredOrders.length > 0) {
                setInfoModalData({
                    type: "error",
                    msg: "La venta ya había sido ingresada.",
                });

                setOrderId("");
                setValidated(false);
                setOrderLoading(false);
                return;
            }

            axios
                .get(env.webApiBaseUrl + "/orders/" + id, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((orderResponse) => {
                    const order = orderResponse.data;
                    if (!order || !order._id) {
                        setInfoModalData({
                            type: "error",
                            msg: "Error al recuperar la venta.",
                        });
                    } else {
                        handleOrderLoaded(order);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude recuperar la venta. Verifica que el código ingresado sea válido.",
                    });
                })
                .finally(() => {
                    setOrderLoading(false);
                });
        },
        [
            csrfToken,
            env.webApiBaseUrl,
            handleOrderLoaded,
            orders,
            setInfoModalData,
            user.token,
        ]
    );

    const handleChange = useCallback(
        (event) => {
            setOrderId(event.target.value);

            // auto submit
            if (event.target.value.length === 24) {
                handleAddOrder(event.target.value);
            }
        },
        [handleAddOrder]
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            setValidated(true);

            handleAddOrder(orderId);
        },
        [handleAddOrder, orderId]
    );

    return (
        <Fragment>
            {packingOrder ?
            <OrderItemsModal 
                packingOrder={packingOrder}
                setPackingOrder={setPackingOrder}
                handleOrderPacked={handleOrderPacked}
            />
            : null
            }
            
            <AddOrderForm
                orderLoading={orderLoading}
                orderId={orderId}
                validated={validated}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
    </Fragment>
    );
};

export default AddOrder;
