import React, { Fragment, useContext } from 'react';
import { useHistory, withRouter } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaSignOutAlt } from "react-icons/fa";
import { FaTools } from "react-icons/fa";

import { UserContext } from '../../context/UserContext';

const Header = withRouter(({location}) => {
    const { user, revokeSession } = useContext(UserContext);
    const history = useHistory();

    const gotoTools = () => {
        history.push("/tools");
    }

    return (
        <Fragment>
        <Navbar 
            className="justify-content-center border-bottom shadow"
            bg="white"
            expand="lg"
            collapseOnSelect={true}
        >
            <Container fluid>

                <Navbar.Brand className="bg-white p-2">
                    <img src="/img/logoGrande.png" height={48} alt="Backoffice Logístico"/>
                </Navbar.Brand>

                {user
                ? <Fragment>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav 
                            className="justify-content-center w-100"
                            fill
                            justify
                            activeKey={location.pathname}
                            //defaultActiveKey={location.pathname}
                        >
                            <Nav.Item>
                                <LinkContainer to="/home">
                                    <Nav.Link href="/home">HOME</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/pickings">
                                    <Nav.Link eventKey="/pickings">PICKING</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/packings">
                                    <Nav.Link eventKey="/packings">PACKING</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/incomplete">
                                    <Nav.Link eventKey="/incomplete">SIN STOCK</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/dispatchs">
                                    <Nav.Link eventKey="/dispatchs">DESPACHO</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/processed">
                                    <Nav.Link eventKey="/processed">PROCESADAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/cancelled">
                                    <Nav.Link eventKey="/cancelled">CANCELADAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/returned">
                                    <Nav.Link eventKey="/returned">DEVUELTAS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                        </Nav>


                        <div
                            className="ml-2 text-center"
                            //style={{ borderLeft: "solid 1px lightgray" }}
                        >
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltipLogout" className="text-center">
                                        Cerrar sesión<br/>
                                        {user.email}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="light"
                                    onClick={() => revokeSession()}
                                >
                                    <FaSignOutAlt style={{ fontSize: "1.2rem", color: "#154f90" }} />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Navbar.Collapse>
                </Fragment>
                : null
                }
                
            </Container>
        </Navbar>

        <br/>
        </Fragment>
    );
});
 
export default Header;