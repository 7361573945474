import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';

const SubHeader = ({ children }) => {
    const navRef = useRef(null);

    useEffect(() => {
        const handleSticky = () => {
            if (navRef.current) {
                const offset = navRef.current.offsetTop;

                if (offset > 105) {
                    navRef.current.className =  "container-fluid sticky bg-white pt-2 pb-2 shadow";
                }
                else {
                    navRef.current.className = "container-fluid sticky bg-white pt-2 pb-2"
                }
            }
        }

        window.addEventListener('scroll', handleSticky);
        return () => {
            window.removeEventListener('scroll', handleSticky);
        }
    }, []);

    return (
        <Container 
            fluid 
            id="subHeader"
            className="sticky bg-white pt-2 pb-2"
            ref={navRef}
        >
            <Container fluid>
                {children}
            </Container>
        </Container>
    );
}
 
export default SubHeader;