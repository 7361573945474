import React, { useRef, useEffect, useCallback } from 'react';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoadingSpinner from "../Layout/LoadingSpinner";

const SearchOrderForm = ({orderLoading, idTypes, search, validated, handleChange, handleSubmit}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const focus = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                //console.log("Input focus", inputRef.current.value);
            }
        }
        setTimeout(focus, 100);
    }, [search]);

    const handleRadioChange = useCallback((group, id, value) => {
        if (value) {
            handleChange(group, id);
        }
    }, [handleChange]);

    return (
        <Container fluid style={{ height: "8rem" }}>
                <br />

                {orderLoading ? (
                    <div>
                        <br />
                        <LoadingSpinner />
                    </div>
                ) : (
                <Container fluid>
                    <Form
                        className="h-100"
                        validated={validated}
                        onSubmit={(event) => handleSubmit(event)}
                    >
                    <Row>
                        <Col className="p-4 border">
                            <Form.Row>
                                <Form.Label className="form-label-title">Ingresar identificador de la venta</Form.Label>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12}>
                                    <Form.Label>{idTypes.find(type => type.id === search.type).name}</Form.Label>
                                    <Form.Control
                                        required
                                        autoFocus
                                        ref={inputRef}
                                        name="value"
                                        type="text"
                                        placeholder={idTypes.find(type => type.id === search.type).placeholder}
                                        value={search.value || ""}
                                        onChange={(event) => handleChange("value", event.target.value)}
                                        maxLength={24}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12}>
                                    <Form.Label>Tipo de identificador</Form.Label>
                                    {idTypes.map((idType) => (
                                        <Col md key={"key_" + idType.id}>
                                            <Form.Check
                                                type="radio"
                                                className=""
                                                name="type"
                                                id={"typeId_" + idType.id}
                                                label={idType.name}
                                                checked={idType.id === search.type}
                                                onChange={(event) => handleRadioChange("type", idType.id, event.currentTarget.checked)}
                                                custom
                                            />
                                        </Col>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>

                    <br />
                    <br />

                    <Row>
                        <Button block type="submit" variant="primary">
                            Generar
                        </Button>
                    </Row>
                    </Form>
                </Container>
                )}
        </Container>
    );
}
 
export default SearchOrderForm;