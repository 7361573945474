import React, { Fragment, useCallback, useContext, useState } from 'react';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

const FinishPartialStockOrdersButton = ({items, selectedOrders, setInfoModalData, setSelectedOrders, fetchOrders}) => {
    const { env, csrfToken } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [processingFinished, setProcessingFinished] = useState(false);

    const handleProcessed = useCallback(
        () => {
            setProcessingFinished(true);

            const finishRequest = {
                selectedOrders: sortSeletedOrdersFromSortedOrders(selectedOrders, items),
            };
    
            console.log("Handling partial stock status finished for: ", finishRequest.selectedOrders);
    
            axios
                .post(env.webApiBaseUrl + "/orders/partials/finish", finishRequest, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-Token": csrfToken,
                        Authorization: "Bearer " + user.token,
                    },
                    withCredentials: true,
                })
                .then((finishResponse) => {
                    const updatedOrders = finishResponse.data.updatedOrders;
                    const ventaWord = updatedOrders === 1 ? " venta marcada " : " ventas marcadas ";

                    setInfoModalData({
                        type: "success",
                        msg: updatedOrders + ventaWord + "como procesadas.",
                    });
    
                    setSelectedOrders([]);
                    fetchOrders();
                })
                .catch((error) => {
                    console.error(error);
                    setInfoModalData({
                        type: "error",
                        msg: "No pude aplicar el cambio de estado.",
                    });
                })
                .finally(() => {
                    setProcessingFinished(false);
                });
        },
        [csrfToken, env.webApiBaseUrl, fetchOrders, items, selectedOrders, setInfoModalData, setSelectedOrders, user.token],
    );

    return (
        <Button
                    variant="success"
                    className="float-right mt-1 mb-2 ml-2"
                    disabled={!selectedOrders.length || processingFinished}
                    onClick={() => handleProcessed()}
                >
                    Marcar ventas como procesadas
                    {processingFinished ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
    );
}
 
export default FinishPartialStockOrdersButton;
