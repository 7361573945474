import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import fileDownload from "js-file-download";
import { FaSync } from "react-icons/fa";

import SubHeader from "../Layout/SubHeader";
import OrderTable from "../Orders/OrdersTable";
import InfoModal from "../Layout/InfoModal";

import { SiteContext } from "../../context/SiteContext";
import { UserContext } from "../../context/UserContext";

import useSortableData from "../../hooks/SortableData";

import { filterDateToQuery, filterGroupToQuery, chunks } from "../../helpers/Query";
import { sortSeletedOrdersFromSortedOrders } from "../../helpers/SortableOrders";
import { getDefaultOrderFiltersSchema } from "../../helpers/Filters";

const Pickings = () => {
    const { env, csrfToken, salesShopGroup } = useContext(SiteContext);
    const { user } = useContext(UserContext);

    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [lastLoadTime, setLastLoadTime] = useState(new Date());

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [printingPicking, setPrintingPicking] = useState(false);

    const [infoModalData, setInfoModalData] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(0);

    const [orderFilters, setOrderFilters] = useState({
        ...getDefaultOrderFiltersSchema(salesShopGroup),
        statuses: [
            { id: "pending", name: "pending", value: true },
            { id: "picking", name: "picking", value: false },
        ],
    });

    const { items, requestSort, sortConfig } = useSortableData(orders, {
        direction: "ascending",
        key: "createdAt",
    });

    const handleOrderSelect = (orderId, checked) => {
        //console.log("Cambia item ", orderId, " checked ", checked);

        if (checked) {
            setSelectedOrders((oldSelectedOrders) => {
                if (oldSelectedOrders.includes(orderId)) {
                    return oldSelectedOrders;
                } else {
                    return [...oldSelectedOrders, orderId];
                }
            });
        } else {
            setSelectedOrders((oldSelectedOrders) =>
                oldSelectedOrders.filter((id) => id !== orderId)
            );
        }
    };

    const fetchOrders = useCallback(() => {
        setOrdersLoading(true);

        let query = "?";
        query += filterGroupToQuery("status", orderFilters.statuses);
        query += filterGroupToQuery("brand", orderFilters.brands);
        query += filterGroupToQuery("shippingType", orderFilters.shippingTypes);
        query += filterDateToQuery("date", orderFilters.date);
        query += filterGroupToQuery("shopGroups", orderFilters.shopGroups);

        axios
            .get(env.webApiBaseUrl + "/orders" + query, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
            })
            .then((ordersResponse) => {
                setOrders(ordersResponse.data);
                setSelectedOrders([]);
            })
            .catch((error) => {
                console.error(error);
                setInfoModalData({
                    type: "error",
                    msg: "No pude recuperar las ventas.",
                    closeButton: true,
                });
            })
            .finally(() => {
                setOrdersLoading(false);
                setLastLoadTime(new Date());
            });
    }, [env, csrfToken, user, setOrdersLoading, setOrders, orderFilters]);

    const handlePrintPickingsForChunk = async (selectedOrdersChunk) => {
        const pickingsRequest = {
            selectedOrders: selectedOrdersChunk,
        };

        console.log(
            "Printing picking tickets for: ",
            pickingsRequest.selectedOrders
        );

        try {
            const pickingsResponse = await axios.post(env.webApiBaseUrl + "/orders/pickings", pickingsRequest, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken,
                    Authorization: "Bearer " + user.token,
                },
                withCredentials: true,
                responseType: "blob",
                timeout: 60000,
            });

            const filename = "pickings-" + Date.now() + ".pdf";
            fileDownload(pickingsResponse.data, filename);

            setSelectedOrders((oldSelectedOrders) =>
                oldSelectedOrders.filter(
                    (order) => !selectedOrdersChunk.includes(order)
                )
            );

            fetchOrders();
        } catch (e) {
            console.error("Problemas al generar pickings para grupo: ", e);
            throw e;
        }
    };

    const handlePrintPickings = async () => {
        setPrintingPicking(true);

        try {
            const selectedOrdersChunks = chunks(
                sortSeletedOrdersFromSortedOrders(selectedOrders, items),
                env.downloadMaxChunkSize
            );

            let chunkNumber = 1;

            for (const selectedOrdersChunk of selectedOrdersChunks) {
                setInfoModalData({
                    type: "loading",
                    msg:
                        "Generando los pickings para el grupo " +
                        chunkNumber +
                        " de " +
                        selectedOrdersChunks.length,
                    closeButton: false,
                });

                await handlePrintPickingsForChunk(selectedOrdersChunk);
                chunkNumber++;
            }

            setInfoModalData(null);
        } catch (error) {
            console.error(error);
            setInfoModalData({
                type: "error",
                msg: "No pude generar las etiquetas.",
                closeButton: true,
            });
        } finally {
            setPrintingPicking(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, orderFilters]);

    useEffect(() => {
        const cleanSelectedOrders = () => {
            // Revisar que todo lo que está en selectedOrders exista
        };

        cleanSelectedOrders();
    }, [orders]);

    useEffect(() => {
        setItemQuantity(orders.reduce((a,b) => a + b.items.reduce((x,y) => x + y.quantity, 0), 0));
    }, [orders]);

    return (
        <div>
            <SubHeader>
                <Button
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={() => fetchOrders()}
                >
                    <FaSync style={{ marginBottom: "3px" }} />
                </Button>
                <Button
                    variant="primary"
                    className="float-right mt-1 mb-2"
                    disabled={!selectedOrders.length || printingPicking}
                    onClick={() => handlePrintPickings()}
                >
                    Descargar etiquetas para picking
                    {printingPicking ? (
                        <Fragment>
                            <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Cargando...</span>
                        </Fragment>
                    ) : (
                        <Badge className="ml-2" variant="light">
                            {selectedOrders.length}
                        </Badge>
                    )}
                </Button>
                <h2>
                    VENTAS PARA PICKING
                    <span className="quantities">
                        <Badge className="ml-3" variant="dark">
                            {orders.length} ventas
                        </Badge>
                        <Badge className="ml-2" variant="info">
                            {itemQuantity} ítems
                        </Badge>
                    </span>
                </h2>
                Última actualización: {lastLoadTime.toLocaleString("es-CL")}
            </SubHeader>
            <br />

            <Container fluid>
                {infoModalData ? (
                    <InfoModal
                        closeButton={infoModalData.closeButton}
                        type={infoModalData.type}
                        msg={infoModalData.msg}
                        setMsg={setInfoModalData}
                    />
                ) : null}

                <OrderTable
                    orders={items}
                    sortConfig={sortConfig}
                    requestSort={requestSort}
                    selectedOrders={selectedOrders}
                    handleOrderSelect={handleOrderSelect}
                    orderFilters={orderFilters}
                    setOrderFilters={setOrderFilters}
                    ordersLoading={ordersLoading}
                />
            </Container>
        </div>
    );
};

export default Pickings;
