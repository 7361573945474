import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from "react-bootstrap/Badge";
import { FaBan } from "react-icons/fa";

const OrderItemsModalHeader = ({packingOrder, packedItems, handleCancel, setPackedItemsInOrder, infoMessageData}) => {
    const itemsQuantity = packingOrder.items.reduce((a,b) => a + b.quantityToPack, 0);
    const packedItemsQuantity = packedItems.reduce((a,b) => a + b.packed, 0);
    //const damagedItemsQuantity = packedItems.reduce((a,b) => a + b.damaged, 0);

    const action = (packingOrder.status === "review" || packingOrder.status === "downloaded" || packedItemsQuantity === itemsQuantity)
        ? "packing"
        : "review";

    return (
        <Modal.Header>
            <Modal.Title>
                CONTENIDO DE LA VENTA

                <Badge className="ml-2 mb-2" variant="info">
                    {itemsQuantity} ítems
                </Badge>
            </Modal.Title>
            <div>
                <Button
                    variant="danger"
                    className="float-right ml-2 mt-1 mb-2"
                    onClick={handleCancel}
                >
                    <FaBan style={{ marginBottom: "3px" }} />
                </Button>
                <Button
                    className="float-right mt-1 mb-2"
                    variant={action === "packing" ? "success" : "warning"}
                    disabled={(action === "packing" && !packedItemsQuantity) || (infoMessageData && infoMessageData.type)}
                    onClick={() => setPackedItemsInOrder(packingOrder, packedItems)}
                >
                    { action === "packing" 
                    ? "Despachar venta " + (packedItemsQuantity !== itemsQuantity ? "incompleta" : "completa")
                    : "Enviar venta a revisión"
                    }

                    <Badge className="ml-2" variant="light">
                        {packedItemsQuantity} ítems
                    </Badge>
                </Button>
            </div>
        </Modal.Header>
    );
}
 
export default OrderItemsModalHeader;