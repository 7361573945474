import React from 'react'
import Modal from 'react-bootstrap/Modal';

import InfoMessage from './InfoMessage';

const InfoModal = ({ type, msg, setMsg, closeButton, retryButton, ok, cancel, size }) => {
    return (
        <Modal
            size={size ? size : "sm"}
            centered
            backdrop="static"
            show={ msg !== '' && msg != null }
        >
            <Modal.Body className="text-center working_modal">
                <InfoMessage
                    type={type}
                    closeButton={closeButton}
                    retryButton={retryButton}
                    msg={msg}
                    setMsg={setMsg}
                    ok={ok}
                    cancel={cancel}
                    size={size}
                />
            </Modal.Body>
        </Modal>
    );
}
 
export default InfoModal;