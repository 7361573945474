import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

import config from '../config';

export const SiteContext = createContext();

const SiteProvider = (props) => {
    const defaultEnv = {
        webApiBaseUrl: config.webApi.BASE_URL + '/' + config.webApi.VERSION,
        downloadMaxChunkSize: config.downloadMaxChunkSize,
    };

    const [ env, setEnv ] = useState(defaultEnv);
    const [ csrfToken, setCsrfToken ] = useState();
    
    const [ salesShopGroup, setSalesShopGroup ] = useState(() => {
        const saved = localStorage.getItem("salesShopGroup");
        return saved || "PrestaShop";
    });

    const [ configLoading, setConfigLoading ] = useState(false);

    useEffect(() => {
        const getHomeConfig = () => {

            setConfigLoading(true);

            axios.get(env.webApiBaseUrl + '/config', { withCredentials: true })
                .then( (homeConfigResponse) => {
                    //console.log("Home config response: ", homeConfigResponse);

                    setCsrfToken( homeConfigResponse.data.xsrf );
                } )
                .catch( (error) => {
                    console.log(error);
                } )
                .finally( () => {
                    setConfigLoading(false);
                } );
        }

        getHomeConfig();

        return () => {
        }
    }, [env]);

    useEffect(() => {
        localStorage.setItem("salesShopGroup", salesShopGroup);
    }, [salesShopGroup])

    return (
        <SiteContext.Provider
            value={{
                env,
                setEnv,
                csrfToken,
                setCsrfToken,
                configLoading,
                salesShopGroup,
                setSalesShopGroup,
            }}
        >
            {props.children}
        </SiteContext.Provider>
    )
}

export default SiteProvider;